import localeDateDE from "date-fns/locale/de"
import localeDateEN from "date-fns/locale/en-US"
import { domainDependencies } from "../domain/Domain.Dependencies"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CONFIG from "../public/config"

export const getApiBaseUrl = (): string => {
  const { hostname } = window.location
  let apiBaseUrl = ""
  const featureTestUrlPattern = /^portal-cbs-\d+\.ebp-dev\.ckw\.ch$/
  const backendFeatureTestUrlPattern = /^portal-cbs-\d+-\d+-test\.ebp-dev\.ckw\.ch$/

  if (
    hostname.includes("localhost") ||
    featureTestUrlPattern.test(hostname) ||
    hostname.includes("portal-preprod.ebp-dev.ckw.ch")
  ) {
    apiBaseUrl = CONFIG?.API_BASE_URL.preProd
  } else if (backendFeatureTestUrlPattern.test(hostname)) {
    apiBaseUrl = `https://${hostname.replace(/^portal/, "ebp-api-service")}`
  } else if (hostname.includes("demo")) {
    apiBaseUrl = CONFIG?.API_BASE_URL.demo
  } else if (hostname.includes("zev.ckw.ch") || hostname.includes("portal.ebp.ckw.ch")) {
    apiBaseUrl = CONFIG?.API_BASE_URL.prod
  }

  return apiBaseUrl
}

const assertNotUndefined = (value: string | undefined, key: string): string => {
  if (!value) throw Error(`env var ${key} not set`)
  return value
}

export const DOMAIN_DEPENDENCIES = domainDependencies({
  baseUrl: getApiBaseUrl(),
  appName: assertNotUndefined(process.env.APP_NAME, "APP_NAME"),
  locale: getLocale(assertNotUndefined(process.env.APP_LANG, "APP_LANG")),
})

function getLocale(lang: string) {
  switch (lang) {
    case "en":
      return localeDateEN
    case "de":
      return localeDateDE
    default:
      throw Error("language not found")
  }
}
